@tailwind base;
p {
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
}
table {
  border-spacing: 0;
}
.swiper-button-disabled {
  opacity: 0.3;
}
.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
  filter: saturate(1.2);
}
.cornerFlickerFix {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body,
  p,
  ol,
  ul,
  li,
  dl,
  dt,
  dd,
  blockquote,
  figure,
  fieldset,
  legend,
  textarea,
  pre,
  iframe,
  hr,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply p-0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-normal;
  }
  img,
  embed,
  object,
  audio,
  video {
    @apply h-auto;
    @apply max-w-full;
  }
  iframe {
    @apply border-none;
  }
  td,
  th {
    @apply p-0;
    @apply text-left;
  }
  html,
  body,
  p,
  ol,
  ul,
  li,
  dl,
  dt,
  dd,
  blockquote,
  figure,
  fieldset,
  legend,
  textarea,
  pre,
  iframe,
  label,
  span,
  hr,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family:
      -apple-system,
      BlinkMacSystemFont,
      HelveticaNeue-Light,
      Helvetica Neue Light,
      Helvetica Neue,
      Helvetica,
      Arial,
      Lucida Grande,
      sans-serif;
    @apply text-lg;
    @apply text-darkGrey;
    @apply dark:text-white;
  }
  ::selection {
    @apply bg-selectionColor;
  }
  body {
    @apply bg-lightGrey;
    @apply dark:bg-black;
  }
  p  {
    @apply text-lg;
    @apply break-words;
  }
  b {
    @apply font-semibold;
  }
  a {
    @apply text-primaryColor;
    @apply no-underline;
  }
  a:hover {
    @apply text-secundaryColor;
  }
  h1 {
    @apply text-4xl;
    @apply font-bold;
  }
  h2 {
    @apply text-2xl;
    @apply font-bold;
  }
  h3 {
    @apply text-lg;
    @apply font-bold;
  }
}

@layer components {
  .pf-text-shadow {
    text-shadow: 0px 0px 2px black;
  }
  .jobDescription p,
  .jobDescription span,
  .jobDescription li,
  .jobDescription h1,
  .jobDescription h2,
  .jobDescription h3 {
    @apply text-darkGrey;
    @apply dark:text-white;
    @apply py-2;
    @apply px-0;
    @apply leading-[30px];
    @apply sm:text-[22px];
    @apply sm:leading-[38px];
    @apply empty:hidden;
  }
  .jobDescription ul {
    @apply list-disc;
    @apply pl-5;
    @apply md:pl-[22px];
  }
  .jobDescription li {
    @apply py-1;
  }
  .jobDescription img {
    display: none;
  }
  .jobDescription h1 {
    @apply text-2xl;
  }
  .jobDescription span {
    @apply py-0;
  }
  .jobDescription a span {
    @apply text-tintColor;
    @apply sm:hover:text-secundaryColor;
  }
  .cornerFlickerFix {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
  }
  .spanFix span {
    @apply sm:text-[22px];
  }
  .overflow-wrap-anywhere {
    overflow-wrap: anywhere;
  }
}
